<h4 mat-dialog-title>{{ formname }}</h4>
<div class="mb-2" mat-dialog-content>
    <pp-loading [show]="loading" [message]='"Loading..."'></pp-loading>
    <div class="col-md-12">
   
        <div class="mat-elevation-z8" style="width: 100%;" *ngIf="dataList.length">
        
          <table style="height: 100%;"
            class="table table-bordered table-striped table-hover"
          >
            <thead>
              <tr style="width: 15px; height: 10PX; font-size: 10px;">
                <th style="width: 30px; height: 10PX; font-size: 10px;">{{ 'prod.order-date' | translate }}</th>
                <th style="width: 35px; height: 10PX; font-size: 10px;">{{ 'prod.order-no' | translate }}</th>             
                  <th style="width: 12px; height: 10PX; font-size: 10px;">{{ 'prod.product-name' | translate }}</th>

                <th style="width: 15px; height: 10PX; font-size: 10px;">{{ 'prod.order-qty' | translate }}</th>
              
                
              </tr>
            </thead>
            <tbody class="table-data">
              <tr style="width: 15px; font-size: 10px;"
                class="bg-light"
                *ngFor="let row of dataList"
              >
                <td style="vertical-align: middle; font-size: 10px;">
                  {{ row.date
                    | date: 'dd/MM/yyyy' }}
                </td>
                <td style="vertical-align: middle; font-size: 10px;">
                    {{ row.documentNo }}
                
                  
                </td>
              
                <td style="vertical-align: middle; font-size: 10px;">
                    {{ row.prodName
                    }}
                </td>
                <td style="vertical-align: middle; font-size: 10px;">
                  {{ row.qty
                  }}
                </td>
             
              
              </tr>
            </tbody>
          </table>
         
        </div>
   
      </div>
</div>


<div class="float-right">
    <div mat-dialog-actions>
        <button color="accent" mat-raised-button mat-dialog-close>Close</button>
    </div>
</div>