import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslatePipe } from '@ngx-translate/core';
import { ContextMenuService, FilterService, FilterSettingsModel, GridComponent, PageService, PageSettingsModel, SortService } from '@syncfusion/ej2-angular-grids';
import { ProductionService,ProductionDetails,SearchProductionPlaning } from 'src/app/services/production/production.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'pp-ref-view-dialog',
  templateUrl: './ref-view-dialog.component.html',
  styleUrls: ['./ref-view-dialog.component.scss'],
  providers: [
    PageService,
    SortService,
    FilterService,
    ContextMenuService
  ]
})
export class RefViewDialogComponent implements OnInit {
  loading: boolean = true;
  formname:string;
  public pageSettings: PageSettingsModel;
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public dateFormat: object;
  public customAttributes: Object;
  public filter: Object;
  washplanlist: Array<ProductionDetails> = [];
  
  productiontodaysDetailsList: Array<ProductionDetails> = [];
  @ViewChild('grid') grid: GridComponent;
  dataList: Array<any> = [];
  constructor(
    public dialogRef: MatDialogRef<RefViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RefViewDetailsParam,
    private translatePipe: TranslatePipe,
    private _snackBar: MatSnackBar,
    private snackBar: MatSnackBar,
    private productionService: ProductionService
  ) { }


  ngOnInit(): void {
    this.customAttributes = { class: 'pp-grid-style' };
    this.filter = { type: "CheckBox" };
    this.dateFormat = { type: 'date', format: 'dd/MM/yyyy' };

    this.dataList=[];

    this.formname="View"
    
   
    this.productionService.GetProductionRefDocumentDetail(this.data.productCd,this.data.statusCd).subscribe(
      (productionPlan) => {
        this.loading = false;
        console.log('********productionPlan', productionPlan);
        this.dataList = productionPlan.sort((a,b) => 0 - (a > b ? 1 : -1));
       
        console.log('********productiontodaysDetailsList', this.dataList);
       
      },
      (err) => {
       
        this._snackBar.open(
          'Unable to fetch production plan details',
          'Close',
          { duration: 5000 }
        );
      }
    );
  }
 
}
export interface RefViewDetailsParam {
  
  productCd: string;
  statusCd: number;
}
